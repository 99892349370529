exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-documentation-login-screen-mdx": () => import("./../../../src/pages/documentation/login-screen.mdx" /* webpackChunkName: "component---src-pages-documentation-login-screen-mdx" */),
  "component---src-pages-documentation-main-history-window-mdx": () => import("./../../../src/pages/documentation/main/history-window.mdx" /* webpackChunkName: "component---src-pages-documentation-main-history-window-mdx" */),
  "component---src-pages-documentation-main-setting-window-mdx": () => import("./../../../src/pages/documentation/main/setting-window.mdx" /* webpackChunkName: "component---src-pages-documentation-main-setting-window-mdx" */),
  "component---src-pages-documentation-main-time-window-mdx": () => import("./../../../src/pages/documentation/main/time-window.mdx" /* webpackChunkName: "component---src-pages-documentation-main-time-window-mdx" */),
  "component---src-pages-documentation-mdx": () => import("./../../../src/pages/documentation.mdx" /* webpackChunkName: "component---src-pages-documentation-mdx" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-elua-js": () => import("./../../../src/pages/elua.js" /* webpackChunkName: "component---src-pages-elua-js" */),
  "component---src-pages-google-delete-js": () => import("./../../../src/pages/google-delete.js" /* webpackChunkName: "component---src-pages-google-delete-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-releases-1-01-js": () => import("./../../../src/pages/releases/1-01.js" /* webpackChunkName: "component---src-pages-releases-1-01-js" */),
  "component---src-pages-releases-1-02-js": () => import("./../../../src/pages/releases/1-02.js" /* webpackChunkName: "component---src-pages-releases-1-02-js" */),
  "component---src-pages-releases-1-03-js": () => import("./../../../src/pages/releases/1-03.js" /* webpackChunkName: "component---src-pages-releases-1-03-js" */),
  "component---src-pages-releases-1-1-1-js": () => import("./../../../src/pages/releases/1-1-1.js" /* webpackChunkName: "component---src-pages-releases-1-1-1-js" */),
  "component---src-pages-releases-1-1-2-js": () => import("./../../../src/pages/releases/1-1-2.js" /* webpackChunkName: "component---src-pages-releases-1-1-2-js" */),
  "component---src-pages-releases-1-1-4-js": () => import("./../../../src/pages/releases/1-1-4.js" /* webpackChunkName: "component---src-pages-releases-1-1-4-js" */),
  "component---src-pages-releases-1-1-5-js": () => import("./../../../src/pages/releases/1-1-5.js" /* webpackChunkName: "component---src-pages-releases-1-1-5-js" */),
  "component---src-pages-releases-1-1-7-js": () => import("./../../../src/pages/releases/1-1-7.js" /* webpackChunkName: "component---src-pages-releases-1-1-7-js" */),
  "component---src-pages-releases-1-1-js": () => import("./../../../src/pages/releases/1-1.js" /* webpackChunkName: "component---src-pages-releases-1-1-js" */),
  "component---src-pages-releases-js": () => import("./../../../src/pages/releases.js" /* webpackChunkName: "component---src-pages-releases-js" */)
}

